import { PageProps } from 'gatsby';
import * as React from 'react';

const NotFoundPage: React.FC<PageProps> = () => (
  <div style={{ padding: 30 }}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that does not exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
